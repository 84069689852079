<template>
  <b-navbar toggleable="lg" type="dark" :variant="headerVariant">
    <b-navbar-brand href="#">
      <img class="float-left" src="@/assets/images/logo_white.svg" alt="sword health logo"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item disabled>{{ selectedEnvAnnotation }}</b-nav-item>
        <b-nav-item><img class="env-icon" @click="mode" :src="envIcon" alt="env"/></b-nav-item>
        <b-nav-item v-if="$isSudo"><img class="env-icon" :src="sudoEnabledIcon" alt="sudo"/></b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item right v-if="user" href="https://www.notion.so/swordhealth/8db2ce6f5bbe44498598cd23fd66a78d" target="_blank">
          AdminUI guide
        </b-nav-item>
        <b-nav-item-dropdown right v-if="user">
          <template #button-content>
            <em>{{ userName }}</em>
          </template>
          <b-dropdown-item v-if="showMyProfile()" to="/my-profile">Profile</b-dropdown-item>
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>

let timeoutID = null;

export default {
  name: 'TopHeader',
  data() {
    return {
      modeClicks: 0,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('User/deleteUserAuth', null, { root: true });
      this.$store.dispatch('Auth/logout', { root: true });
      window.location = '/';
    },
    showMyProfile() {
      return this.$acl.check('view');
    },
    mode() {
      if (this.modeClicks === 0) {
        timeoutID = setTimeout(() => {
          this.modeClicks = 0;
        }, 3000);
      }
      this.modeClicks = this.modeClicks + 1;
      if (this.modeClicks === 5) {
        const path = window.location.href.split('?')[0];
        const search = new URLSearchParams(window.location.href.split('?')[1] || '');
        if (this.$isSudo) {
          search.set('mode', 'off');
        } else {
          search.set('mode', 'sudo');
        }
        this.modeClicks = 0;
        clearTimeout(timeoutID);
        document.location.href = `${path}?${search.toString()}`;
      }
    },
  },
  computed: {
    headerVariant() {
      return this.$isSudo ? 'danger' : 'info';
    },
    userName() {
      return `${this.user.firstname} ${this.user.lastname}`;
    },
    selectedEnvAnnotation() {
      const availableApis = this.$store.getters['Core/getAvailableApis'];
      const selectedApi = this.$store.getters['Core/getSelectedApi'];

      let envName = '';
      Object.keys(availableApis).forEach(stabilityKey => {
        Object.keys(availableApis[stabilityKey]).forEach(envKey => {
          if (availableApis[stabilityKey][envKey].api_url === selectedApi) {
            envName = envKey;
          }
        });
      });

      return envName ? `| ${envName}` : '';
    },
    user() {
      return this.$store.getters['User/profile'];
    },
    sudoEnabledIcon() {
      return require('@/assets/slack/slack-fire.png');
    },
    envIcon() {
      switch (this.$store.getters['Core/getSelectedEnv']) {
        case 'staging':
          return require('@/assets/slack/slack-eagle.png');
        case 'us':
          return require('@/assets/slack/slack-eagle.png');
        case 'ca':
          return require('@/assets/slack/slack-deer.png');
        case 'eu':
          return require('@/assets/slack/slack-ronaldo_triste.png');
        case 'uk':
          return require('@/assets/slack/slack-beer.png');
        default:
          return require('@/assets/slack/slack-kanguroo.png');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.env-icon {
  max-height: 25px;
  cursor: not-allowed;
}
</style>
