<template>
  <div class="main-view">
    <top-header></top-header>
    <side-nav class="sidebar"></side-nav>
    <main role="main" class="content-holder py-3">
      <router-view/>
    </main>
  </div>
</template>


<script>
import TopHeader from '@/components/Layout/TopHeader.vue';
import SideNav from '@/components/Layout/SideNav.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Main',
  components: {
    TopHeader,
    SideNav,
  },
  beforeMount() {
    if (!this.isLoggedIn) {
      this.$router.replace('/login');
    }
  },
  mounted() {
    if (this.isLoggedIn && this.isSftpSettingsEnabled) {
      this.$store.dispatch('Sftp/DryRun/pollForUserNotifications');
    }
  },
  computed: {
    isLoggedIn() {
      return this.$acl.check('view');
    },
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  watch: {
    isLoggedIn(val) {
      if (!val) {
        this.$router.replace('/login');
      }
    },
  },
};
</script>

<style lang="scss">

$header-height: 60px;
$sidebar-base-width: 220px;

.main-view {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: $sidebar-base-width calc(100% - #{$sidebar-base-width});
  grid-template-rows: $header-height calc(100% - #{$header-height});
  grid-template-areas: 'header header''sidebar main';

  & > nav {
    grid-area: header;
  }

  & > .sidebar {
    grid-area: sidebar;
    overflow: scroll;
    background-color: $context-color-7;
  }

  & > .content-holder {
    grid-area: main;
  }
}

.content-holder {
  overflow: scroll;
}

</style>
