<template>
  <div class="app-sidebar-menu">
    <ul class="nav">
      <li class="nav-item" v-for="item in visibleItems" :key="item.key">

        <a v-if="item.noLink" href="javascript:" v-b-toggle="'collapse-'+item.dropdownKey" class="nav-link">
          <feather v-if="item.icon" :type="item.icon"></feather>
          <span> {{ item.key }} </span>
          <feather v-if="item.items && item.items.length" type="chevron-down"></feather>
        </a>

        <router-link v-else-if="!item.href" v-b-toggle="'collapse-'+item.dropdownKey"
                     class="nav-link" :to="item.route ? item.route.path : '/'">
          <feather v-if="item.icon" :type="item.icon"></feather>
          <span> {{ item.key }} </span>
          <feather v-if="item.items && item.items.length" type="chevron-down"></feather>
        </router-link>

        <a v-else :href="item.href" v-b-toggle="'collapse-'+item.dropdownKey" class="nav-link" target="_blank">
          <feather v-if="item.icon" :type="item.icon"></feather>
          <span> {{ item.key }} </span>
          <feather v-if="item.items && item.items.length" type="chevron-down"></feather>
        </a>

        <!-- Collapsed sub-items -->
        <b-collapse :id="'collapse-' + item.dropdownKey">
          <div v-for="(subItem, subItemKey) in item.subItemAllowed" :key="subItemKey" :sub-item="subItem">
            <a v-if="subItem.noLink" href="javascript:" class="nav-link submenu">
              <span> {{ subItem.key }} </span>
              <b-badge v-if="subItem.deprecated" class="ml-1" variant="warning">Old</b-badge>
              <b-badge v-if="subItem.beta" class="ml-1" variant="primary">Beta</b-badge>
            </a>
            <router-link v-else-if="!subItem.href" class="nav-link submenu" :to="subItem.route ? subItem.route.path : '/'">
              <span> {{ subItem.key }} </span>
              <b-badge v-if="subItem.deprecated" class="ml-1" variant="warning">Old</b-badge>
              <b-badge v-if="subItem.beta" class="ml-1" variant="primary">Beta</b-badge>
            </router-link>
            <a v-else class="nav-link submenu" target="_blank" :href="subItem.href">
              <span> {{ subItem.key }} </span>
              <b-badge v-if="subItem.deprecated" class="ml-1" variant="warning">Old</b-badge>
              <b-badge v-if="subItem.beta" class="ml-1" variant="primary">Beta</b-badge>
            </a>
          </div>
        </b-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import translations from '@/translations';
import appConfigs from '@/config';

export default {
  name: 'SideNav',
  methods: {
    topLevelItemVisible(item) {
      return ((item.subItemAllowed && item.subItemAllowed.length) || !item.noLink || item.href) && item.enable !== false;
    },
    generateDropdownKey(item) {
      return item.key.replace(/[. ]/g, '_');
    },
  },
  computed: {
    ...mapGetters({
      userCanSftpSettings: 'User/canSftpSettings',
      canAccessSftpFileImporters: 'User/canAccessSftpFileImporters',
      canAccessSftpFileTypes: 'User/canAccessSftpFileTypes',
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      userIsAdmin: 'User/isAdmin',
    }),
    visibleItems() {
      return this.menuEntries.filter(entry => this.topLevelItemVisible(entry));
    },
    menu() {
      // TODO Rename this env
      const csmURL = (appConfigs.get('VUE_APP_URL_OLD_ADMIN_UI') || '').replace(/\/$/, '');
      const items = [
        {
          key: 'Home',
          icon: 'home',
        },
        {
          key: 'Clients',
          noLink: true,
          icon: 'globe',
          items: [
            { key: 'List', routeName: 'ListOnboardingClients' },
            { key: 'Revision requests', routeName: 'RevisionRequests' },
            { key: 'Institutions', routeName: 'Institutions' },
            { key: 'Eligibility settings', routeName: 'EligibilityConfigs' },
            { key: 'Eligibility tester', routeName: 'TestEligibility' },
            { key: 'Integrations', routeName: 'ClientIntegrations' },
            { key: 'Payers', routeName: 'Payers' },
            { key: 'Marketing touchpoints', routeName: 'ListMarketingTouchpoints' },
          ],
        },
        {
          key: 'C. Support',
          noLink: true,
          icon: 'phone-incoming',
          items: [
            { key: 'Eligibility attempts', routeName: 'ListEligibilityAttempts', url: '/onboarding/eligibility-attempts' },
            {
              key: 'Eligibility Failures',
              routeName: 'ListEligibilityFailures',
              url: '/onboarding/eligibility-failures',
              beta: true,
              enabled: () => this.isSftpSettingsEnabled,
            },
            { key: 'Enrollments', routeName: 'ListEnrollmentsV2' },
            { key: 'Eligibility whitelist', routeName: 'ClientWhitelist', url: '/onboarding/whitelist' },
            { key: 'Search eligibility files', routeName: 'QueryFinder', url: '/onboarding/query-finder' },
            { key: 'Member search', routeName: 'OpsSearchProgram' },
            { key: 'Unsubscribe', routeName: 'Unsubscribe' },
            { key: 'VIP accounts', routeName: 'VIPAccounts' },
          ].filter(item => {
            if (item.enabled === undefined) {
              return true;
            }
            return item.enabled();
          }),
        },
        {
          key: 'Order Manag.',
          noLink: true,
          icon: 'file-text',
          items: [
            { key: 'SKU configs', routeName: 'SKUConfigs', url: '/skuconfigs' },
            { key: 'Ship via configs', routeName: 'ShipVia', url: '/order-management/ship-via' },
            { key: 'Excluded clients', routeName: 'ClientConfigs', url: '/client-configs' },
            { key: 'Orders', routeName: 'Orders', url: '/orders' },
            { key: 'Returns', routeName: 'Returns', url: '/order-management/returns' },
            { key: 'Stock', routeName: 'Stock', url: '/order-management/stock' },
          ],
        },
        {
          key: 'Finance',
          noLink: true,
          icon: 'dollar-sign',
          items: [
            { key: translations.finance.menubar.transactions, routeName: 'FinanceTransactions', url: '/finance/transactions' },
            { key: translations.finance.menubar.transactions_pepm, routeName: 'FinanceTransactionsPEPMList', url: '/finance/transactions-pepm' },
            { key: translations.finance.menubar.import_transactions, routeName: 'FinanceManualTransactions', url: '/finance/import-transactions' },
            { key: translations.finance.menubar.statements, routeName: 'FinanceStatementsPage', url: '/finance/statements' },
            { key: translations.finance.menubar.claims_batches, routeName: 'FinanceClaimsBatchesList', url: '/finance/claims-batches' },
            { key: translations.finance.menubar.invoices, routeName: 'FinanceInvoicesList', url: '/finance/invoices' },
            { key: translations.finance.menubar.billing_reports, routeName: 'FinanceBillingReportsPage', url: '/finance/billing-reports' },
            { key: translations.finance.menubar.blocklist, routeName: 'FinanceBlocklist', url: '/finance/blocklist' },
            { key: translations.finance.menubar.monthly_revenue, routeName: 'FinanceMonthlyRevenueList', url: '/finance/monthly-revenue' },
            { key: translations.finance.menubar.updates, routeName: 'FinanceUpdatesList', url: '/finance/updates' },
          ],
        },
        {
          key: 'Commissions',
          noLink: true,
          icon: 'dollar-sign',
          items: [
            { key: translations.commissions.sidenav.payouts, routeName: 'Statements', url: '/commissions/statements' },
            { key: translations.commissions.sidenav.configurations, routeName: 'Deals', url: '/commissions/deals' },
          ],
        },
        {
          key: 'Finance Operations',
          noLink: true,
          icon: 'box',
          items: [
            {
              key: translations.finance_operations.sidenav.claims_tasks,
              routeName: 'FinanceOperationsClaimAccounts',
              url: '/finance-operations/claim-accounts',
            },
          ],
        },
        {
          key: 'Users',
          noLink: true,
          icon: 'users',
          items: [
            { key: 'Users list', routeName: 'UsersList' },
            { key: 'Professionals list', routeName: 'ProfessionalsList' },
            { key: 'New user', routeName: 'NewUser' },
            { key: 'My profile', routeName: 'MyProfile' },
          ],
        },
        {
          key: 'ClinOps Manag.',
          noLink: true,
          icon: 'users',
          items: [
            { key: 'Bulk Transfers', routeName: 'MembersBulkTransfer' },
            { key: 'Rule Editor', routeName: 'RuleEditor' },
          ],
        },
        {
          key: 'Configs',
          noLink: true,
          icon: 'sliders',
          items: [
            { key: 'System', routeName: 'Configs' },
            { key: 'Exercises', routeName: 'Exercises' },
            { key: 'Referral rules', routeName: 'Referral Rules' },
          ],
        },
        {
          key: 'Other tools',
          noLink: true,
          icon: 'external-link',
          items: [
            { key: 'Systems (CSM)', acl: 'productionOrAdmin', href: `${csmURL}` },
            { key: 'Content manager', href: '/platform-cms', icon: 'book-open' },
            // { key: 'SMS sender', icon: 'mail', routeName: 'SMS Sender' },
            { key: 'Member search', routeName: 'OpsSearchProgramOld', deprecated: true },
          ],
        },
        {
          key: 'Care Ecosystem',
          noLink: true,
          icon: 'globe',
          items: [
            { key: 'Ecosytem vendors', routeName: 'CareEcosystemVendors' },
            { key: 'Ecosytem services', routeName: 'CareEcosystemServices' },
          ],
        },
      ];

      if (this.$isSudo) {
        items.push({
          key: 'ACL Module',
          icon: 'lock',
          routeName: 'AclLists',
        });
        items.push({
          key: 'Netsuite Clients',
          icon: 'lock',
          routeName: 'ClientsNetSuiteList',
        });
        items.push({
          key: 'SamlCore',
          noLink: true,
          icon: 'grid',
          items: [
            { key: 'Configurations', routeName: 'SamlCoreConfigurationList' },
            { key: 'Partners', routeName: 'SamlCorePartnerList' },
            { key: 'Connections', routeName: 'SamlCoreConnectionList' },
          ],
        });
      }

      if (this.isSftpSettingsEnabled && this.userCanSftpSettings) {
        const data = {
          key: 'SFTP',
          noLink: true,
          icon: 'globe',
          items: [
            { key: 'Clients', routeName: 'SFTPClientsList' },
            { key: 'Providers', routeName: 'SFTPProvidersList' },
            { key: 'Eligibility File Tracking', routeName: 'EligibilityFileTracking' },
          ],
        };

        if (this.userIsAdmin) {
          data.items.push({ key: 'System Executions', routeName: 'SFTPSystemExecutions', beta: true });
          data.items.push({ key: 'Remote Servers', routeName: 'SFTPRemoteServersList' });
        }

        if (this.canAccessSftpFileTypes) {
          data.items.push({ key: 'File Types', routeName: 'SFTPGenericImporterList' });
        }

        if (this.canAccessSftpUsersAndGroups) {
          data.items.push({ key: 'Internal Users', routeName: 'SFTPInternalUsersList' });
          data.items.push({ key: 'External Users', routeName: 'SFTPExternalUsersList' });
          data.items.push({ key: 'Internal Groups', routeName: 'SFTPInternalGroupsList' });
          data.items.push({ key: 'External Groups', routeName: 'SFTPExternalGroupsList' });
        }

        items.push(data);
      }

      return items;
    },
    menuEntries() {
      const allLoggedIn = this.$acl.check('view');

      const mainEntriesAllowed = this.menu.filter(item => {
        if (item.noLink) {
          return true;
        }
        const itemRouteName = item.routeName || item.key;
        const itemRouteConfig = this.$router.match({ name: itemRouteName });
        item.route = itemRouteConfig;
        const routeRule = itemRouteConfig.meta.rule;
        const routeRuleSet = this.$acl.check(routeRule);
        return routeRule ? routeRuleSet : allLoggedIn;
      });

      return mainEntriesAllowed.map(item => {
        item.dropdownKey = this.generateDropdownKey(item);
        if (item.items) {
          item.subItemAllowed = item.items.filter(subitem => {
            if (!subitem.routeName) {
              return true;
            }
            subitem.dropdownKey = this.generateDropdownKey(subitem);
            const subItemRouteName = subitem.routeName || subitem.key;
            const subItemRouteConfig = this.$router.match({ name: subItemRouteName });
            subitem.route = subItemRouteConfig;

            const routeRule = subItemRouteConfig.meta.rule;
            const routeRuleOk = this.$acl.check(routeRule);
            return routeRule ? routeRuleOk : allLoggedIn;
          });
        }

        return item;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-item {
  width: 100%;

  a.nav-link,
  a.nav-link::v-deep+div .submenu {
    color: $dark-grey;
  }

  a.nav-link {
    &>span {
      font-size: 14px;
    }
    display: flex;
    align-items: center;

    &.active {
      color: $accent-color;
    }

    &::v-deep+div .submenu {
      font-size: 12px;
      margin-left: 15px;
      .badge {
        font-size: 10px;
      }
    }

    &:hover,
    &.active {
      .feather {
        color: inherit;
      }
    }

    &::v-deep .feather {
      margin-right: 0.5rem;
      color: $shitty-grey;
    }

    &::v-deep .feather--chevron-down {
      margin-left: auto;
    }
  }
}
</style>
